<template lang="">
    <Bannercomponent :tituloBannerSection="TituloBanner" />
    <div class="content-section" style="padding: 5rem 0.5em;">
        <div class="container-xl d-flex flex-column justify-content-center">
            <div class="title-custom bg-transform">
                <h1 class="h1">libro de reclamaciones</h1>
                <p class="lead2"></p>
            </div>
            <div class="row row-cards">
                <div class="col-md-6 col-xl-6">
                    <div class="card card-sm bg-muted text-white">
                        <div class="card-body">
                            <div class="mb-2 py-2">
                                <label class="form-label">Nombres</label>
                                <input type="text" class="form-control" name="Nombres" placeholder="Nombres">
                            </div>
                            <div class="mb-2 py-2">
                                <label class="form-label">Email</label>
                                <input type="text" class="form-control" name="Nombres" placeholder="Email">
                            </div>
                            <div class="mb-2 py-2">
                                <label class="form-label">Asunto</label>
                                <input type="text" class="form-control" name="Nombres" placeholder="Asunto">
                            </div>
                            <div class="mb-2 py-2">
                                <label class="form-label">Contenido</label>
                                <textarea class="form-control" name="example-textarea-input" rows="6" placeholder="Escriba el contenido"></textarea>
                            </div>
                        </div>
                        <div class="card-footer text-end">
                            <div class="d-flex">
                                <button type="submit" class="btn btn-primary ms-auto">Enviar</button>
                            </div>
                        </div>
    
                    </div>
                </div>
                <div class="col-md-6 col-xl-6">
                    <div class="card">
                        <div class="card-body">
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import Bannercomponent from '@/components/partials/Bannercomponent.vue';
    export default {
        components: {
            Bannercomponent
        },
        mounted() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    },
        data() {
            return {
                TituloBanner: 'Libro de Reclamaciones'
            }
        },
    }
    </script>
    
    <style lang="">
    
        </style>
    