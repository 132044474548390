<template>
  <div class="page">
    <NavBarComponent />
    <router-view />
    <MessengerButton />
    <WhatsappComponent />
  </div>
  <FooterComponent />
</template>

<script>
import MessengerButton  from "@/components/modules/MessengerApi.vue";
import WhatsappComponent from "@/components/modules/Whatsapp.vue";
import NavBarComponent from "@/components/partials/NavBar.vue";
import FooterComponent from "@/components/partials/Footer.vue";
export default {
  components: {
    FooterComponent,
    NavBarComponent,
    MessengerButton,
    WhatsappComponent,
  },
  mounted() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    },
};
</script>
<style>
:root {
  --vsc-bg-header: #46C056 !important;
  --vsc-bg-footer: #ffffff !important;
  --vsc-text-color-header: #333333;
  --vsc-text-color-footer: #333 !important;
  --vsc-bg-button: #46C056 !important;
  --vsc-text-color-button: #ffffff;
  --vsc-outline-color: #289D37 !important;
  --vsc-border-color-bottom-header: #289D37 !important;
  --vsc-border-color-top-footer: #b9bbb9 !important;
}

.header {
  background-color: var(--vsc-bg-header);
  color: var(--vsc-text-color-header);
}

.footer {
  background-color: var(--vsc-bg-footer);
  color: var(--vsc-text-color-footer);
}

.button {
  background-color: var(--vsc-bg-button);
  color: var(--vsc-text-color-button);
  outline-color: var(--vsc-outline-color);
}
.vsc-popup-button {
    padding-top: 0 !important;
}
.vsc-popup {
    bottom: 100px !important;
}
</style>