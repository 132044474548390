<template lang="">
<div class="info">
    <div class="container-xl">
        <div class="row text-center align-items-center">
            <div class="col-lg-3 col-sm-12">
                <img src="../../assets/jockey-club-chiclayo.png" alt="" style="width: 200px" />
            </div>
            <div class="col-lg-3 col-sm-12">
                <h1>Teléfono</h1>
                <h3>(074) 232451</h3>
            </div>
            <div class="col-lg-3 col-sm-6">
                <h1>Dirección</h1>
                <h3>Los Incas 379, La Victoria, Chiclayo.</h3>
            </div>
            <div class="col-lg-3 col-sm-6">
                <h1>Horarios de Atención</h1>
                <h3>Lunes a viernes 09.00 am. - 06.00 pm.</h3>
            </div>
        </div>
    </div>
</div>
<footer class="footer">
    <div class="container">
        <div class="row text-center align-items-center">
            <div class="col-lg-auto ms-lg-auto">
                <ul class="list-inline list-inline-dots mb-0">
                    <li class="list-inline-item">
                        Copyright &copy; <strong>2023 Inversiones Ch Computer S.R.L.</strong>
                        Todos los derechos reservados.
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.footer{
    background-color: #484445 !important;
    color: #96D032 !important;
}
.info {
    background-color: #8080802f;
    width: 100%;
    padding: 2rem 0;
    text-align: center;
}

.info h1 {
    font-style: italic;
}
</style>
