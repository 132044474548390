
import "bootstrap/dist/css/bootstrap.min.css";
import * as Popper from "@popperjs/core"
import "bootstrap/dist/js/bootstrap.min";

// import { createApp } from 'vue'
import * as Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'

import VueSocialChat from 'vue-social-chat'
import 'vue-social-chat/dist/style.css'
import "@/assets/css/style.css";
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
import VueTablerIcons from "vue-tabler-icons";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import router from './router'
import store from './store'

// const app = createApp(App)
const app = Vue.createApp(App)
app.use(VueAxios, axios)
app.component('QuillEditor', QuillEditor)
app.use(VuePlyr, {
    plyr: {}
  })
.use(store).use(Popper).use(VueTablerIcons).use(router).use(VueSocialChat).mount('#app')