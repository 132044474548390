<template>
<div id="carousel-captions" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
        <div class="carousel-item" v-for="(banner, index) in banners" :key="banner.ps_id" :class="{ active: index === 0 }">
            <img :src="getFullImagePathBanner(banner.ps_image)" alt="Imagen del banner" class="d-block" style="background-size: contain" />
            <div class="carousel-caption-background d-none d-md-block"></div>
            <div class="carousel-caption d-none d-md-block deformcaption">
                <h2>{{ banner.ps_texto1 }}</h2>
                <p>{{ banner.ps_texto2 }}</p>
            </div>
        </div>
    </div>
    <a class="carousel-control-prev" href="#carousel-captions" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carousel-captions" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
    </a>
</div>
<div class="title-section" style="background-color: #96d032; padding: 4rem 0.5em">
    <div class="container-xl d-flex flex-column justify-content-center">
        <div class="section-title">
            <h1>Jockey <strong style="color: #fff">Club</strong> de Chiclayo</h1>
            <br />
            <p class="lead">Bienvenidos a nuestro sitio web</p>
        </div>
    </div>
</div>
<div class="content-section">
    <div class="container-xl d-flex flex-column justify-content-center">
        <div class="title-custom bg-transform">
            <h1 class="h1">últimas novedades</h1>
            <p class="lead2"></p>
        </div>
        <FacebookPosts />
        <div class="row">
            <div class="col-6 col-sm-4 col-md-2 col-xl py-3 text-center">
                <router-link to="/publicaciones" class="btn btn-lg  btn-outline-blue w-25" aria-current="page" href="#">
                    <span class="nav-link-title">VER TODAS LAS NOTICIAS</span>
                </router-link>
            </div>
        </div>
    </div>
</div>

<div class="content-section bg-muted">
    <div class="container-xl d-flex flex-column justify-content-center">
        <div class="title-custom bg-transform">
            <h1 class="h1">junta directiva</h1>
            <p class="lead2"></p>
        </div>
        <div class="row row-deck row-cards">
            <div class="col-sm-6 col-lg-3 col-xl-6" v-for="presidente in presidentes" :key="presidente.ps_id">
                <div class="card card-sm">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-4">
                                <img :src="getFullImagePathDirectivos(presidente.ps_image)" class="object-cover card-img-start rounded" alt="Imagen" style="width: 200px;" />
                            </div>
                            <div class="col">
                                <h1 class="m-0 mb-1">
                                    <div class="text-muted">{{ presidente.ps_name }} {{ presidente.ps_surname }}</div>
                                </h1>
                                <div class="text-white badge bg-lime" style="text-transform: uppercase">
                                    <strong>{{ presidente.cargoid_name }}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3 col-xl-6" v-for="manager in managers" :key="manager.ps_id">
                <div class="card card-sm">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-4">
                                <img :src="getFullImagePathDirectivos(manager.ps_image)" class="object-cover card-img-start rounded" alt="Imagen" style="width: 200px;" />
                            </div>
                            <div class="col">
                                <h1 class="m-0 mb-1">
                                    <div class="text-muted">{{ manager.ps_name }} {{ manager.ps_surname }}</div>
                                </h1>
                                <div class="text-white badge bg-azure" style="text-transform: uppercase">
                                    <strong>{{ manager.cargoid_name }}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-sm-4 col-md-2 col-xl py-3 text-center">
                <router-link to="/staff" class="btn btn-lg bg-primary text-white w-25" aria-current="page" href="#">
                    <span class="nav-link-title">VER DIRECTIVOS</span>
                </router-link>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import HttpService from "../Services/HttpService";
import FacebookPosts from "@/components/partials/FacebookPosts.vue";
export default {
    name: "HomeView",
    data() {
        return {
            banners: [],
            presidentes: [],
            managers: [],
        };
    },
    components: {
        FacebookPosts,

    },
    mounted() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    },
    computed: {},
    mounted() {
        this.obtenerBanners();
        this.obtenerPresidente();
        this.obtenerManagers();
    },
    methods: {
        // llamado banners
        obtenerBanners() {
            HttpService.obtener("obtener_banners.php").then((resultado) => {
                this.banners = resultado;
            });
        },
        getFullImagePathBanner(imagePath) {
            return `https://xadm.jockeyclubchiclayo.pe/public/images/Sliders/${imagePath}`; // Reemplaza "/static" con la ruta configurada en tu servidor de archivos estáticos
        },
        getFullImagePathDirectivos(imagePath) {
            // return `http://jockeyadmin.ml/public/images/directivos/${imagePath}`;
            return `https://xadm.jockeyclubchiclayo.pe/public/images/directivos/${imagePath}`;
        },
        obtenerPresidente() {
            HttpService.obtener("obtener_presidente.php").then((resultado) => {
                this.presidentes = resultado;
            });
        },
        obtenerManagers() {
            HttpService.obtener("obtener_managers.php").then((resultado) => {
                this.managers = resultado;
            });
        },
    },
};
</script>

<style>
.deformcaption {
    max-width: 350px;
    /* width: auto; */
    left: 10%;
    border-radius: 10px;
    background-color: rgba(17, 17, 17, 0.555);
    margin: 100px 0;
    justify-content: center;
    align-items: center;
}

.deformcaption h2,
.deformcaption p {
    position: relative;
    padding: 0rem 2rem;
    color: white;
    text-align: left;
    text-transform: uppercase;
    font-style: italic;
    margin: 0;
}

.carousel-inner img {
    width: 100%;
    height: 65vh;
    background-size: cover;
    object-fit: cover;
}
</style>
