<template lang="">
<Bannercomponent :tituloBannerSection="TituloBanner" />
<div class="content-section">
    <div class="container-xl d-flex flex-column justify-content-center">
        <div class="title-custom bg-transform">
            <h1 class="h1">Presidente <strong style="color: #96D032;"> Jockey Club</strong></h1>
            <p class="lead2"></p>
        </div>
        <div class="col-sm-6 col-lg-3 col-xl-6 mb-6" v-for="presidente in presidentes" :key="presidente.ps_id">
            <div class="card bg-muted-lt card-sm">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-4">
                            <img :src="getFullImagePath(presidente.ps_image)" class="object-cover card-img-start rounded" alt="Imagen" style="width: 200px;" />
                        </div>
                        <div class="col">
                            <h1 class="m-0 mb-1">
                                <div class="text-muted">{{ presidente.ps_name }} {{ presidente.ps_surname }}</div>
                            </h1>
                            <div class="text-white badge bg-lime" style="text-transform: uppercase">
                                <strong>{{ presidente.cargoid_name }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="title-custom bg-transform">
            <h1 class="h1">Junta directiva <strong style="color: #96D032;"> Jockey club</strong></h1>
            <p class="lead2"></p>
        </div>
        <div class="row row-cards">
            <div class="col-md-6 col-lg-3 mb-6" v-for="staff in staffs" :key="staff.ps_id">
                <div class="card row-deck bg-muted-lt ">
                    <div class="card-body p-4 text-center text-uppercase bg-muted-lt">
                        <img :src="getFullImagePath(staff.ps_image)" class="avatar avatar-xl mb-3 rounded img-fluid h-100" alt="Imagen">
                        <h3 class="m-0 mb-1"><a href="#">{{staff.ps_name}} <br> {{staff.ps_surname}}</a></h3>
                        <div class="badge bg-muted " style="text-transform:uppercase;"><strong>{{ staff.cargoid_name }}</strong></div>
                    </div>
                </div>
            </div>
        </div>
  
        <div class="title-custom bg-transform">
            <h1 class="h1">Gerente General <strong style="color: #96D032;"> Jockey Club</strong></h1>
            <p class="lead2"></p>
        </div>
        <div class="col-sm-6 col-lg-3 col-xl-6 mb-6" v-for="manager in managers" :key="manager.ps_id">
                <div class="card bg-muted-lt card-sm">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-4">
                                <img :src="getFullImagePath(manager.ps_image)" class="object-cover card-img-start rounded" alt="Imagen" style="width: 200px;" />
                            </div>
                            <div class="col">
                                <h1 class="m-0 mb-1">
                                    <div class="text-muted">{{ manager.ps_name }} {{ manager.ps_surname }}</div>
                                </h1>
                                <div class="text-white badge bg-azure" style="text-transform: uppercase">
                                    <strong>{{ manager.cargoid_name }}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>
</template>

<script>
import HttpService from "../Services/HttpService";
import Bannercomponent from '@/components/partials/Bannercomponent.vue';
export default {
    components: {
        Bannercomponent
    },

    data() {
        return {
            TituloBanner: "Nuestra Junta Directiva",
            staffs: [],
            managers: [],
            presidentes: [],
        }
    },
    mounted() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        this.obtenerStaffs();
        this.obtenerManagers();
        this.obtenerPresidente();
    },
    methods: {
        getFullImagePath(imagePath) {
            return `https://xadm.jockeyclubchiclayo.pe/public/images/directivos/${imagePath}`;
        },
        obtenerStaffs() {
            HttpService.obtener("obtener_staffs.php").then((resultado) => {
                this.staffs = resultado;
            });
        },
        obtenerManagers() {
            HttpService.obtener("obtener_managers.php").then((resultado) => {
                this.managers = resultado;
            });
        },
        obtenerPresidente() {
            HttpService.obtener("obtener_presidente.php").then((resultado) => {
                this.presidentes = resultado;
            });
        },

    },
}
</script>
