<template lang="">
<Bannercomponent :tituloBannerSection="TituloBanner" />
<div class="content-section">
    <div class="container-xl d-flex flex-column justify-content-center">
        <div class="title-custom bg-transform">
            <h1 class="h1">VIDEOS PUBLICADOS</h1>
            <p class="lead2"></p>
        </div>

        <div class="row row-cards">
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12" v-for="video in paginateVideos()" :key="video.ps_id">
                <div class="card">
                    <div class="card-body">
                        <vue-plyr>
                            <div class="plyr__video-embed ">
                                <iframe :src="video.ps_url" allowfullscreen allowtransparency allow="autoplay"></iframe>
                            </div>
                        </vue-plyr>
                        <br>
                        <h5 class="card-title">{{ video.ps_title }}</h5>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="py-5">
            <ul class="pagination ms-auto" style="
            text-align: center;
            justify-content: center;
            align-items: center;
          ">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <a class="page-link" href="#" tabindex="-1" aria-disabled="true" @click.prevent="currentPage -= 1">
                        <chevron-left-icon />
                        <small>ATRÁS</small>
                    </a>
                </li>
                &nbsp;

                <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                    <a class="btn page-link" href="#" @click.prevent="currentPage = page">{{ page }}</a> &nbsp;
                </li>
                &nbsp;
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                    <a class="page-link" href="#" @click.prevent="currentPage += 1">
                        <small>SIGUIENTE</small>
                        <chevron-right-icon />
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
import HttpService from '../Services/HttpService';
import Bannercomponent from '@/components/partials/Bannercomponent.vue';
export default {
    components: {
        Bannercomponent
    },
    data() {
        return {
            TituloBanner: "Nuestros Videos",
            videos: [],
            currentPage: 1,
            videosPerPage: 8,
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.videos.length / this.videosPerPage);
        }
    },
    mounted() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        this.obtenerVideos();
    },
    methods: {
        obtenerVideos() {
            HttpService.obtener("obtener_videos.php")
                .then(resultado => {
                    this.videos = resultado;
                });
        },

        paginateVideos() {
            const startIndex = (this.currentPage - 1) * this.videosPerPage;
            const endIndex = startIndex + this.videosPerPage;
            return this.videos.slice(startIndex, endIndex);
        }

    },
}
</script>

<style lang="">

</style>
