<template>
  <div class="layout">
    <!-- ommited -->
    <SocialChat icon :attendants="attendants" >
      <template #header>
        <p>
          Haga clic en uno de nuestros asistentes a continuación para chatear en
          WhatsApp.
        </p>
      </template>
      <template #button>
        <img
          src="../../assets/whatsappblack.png"
          alt="icon whatsapp"
          aria-hidden="true"
          style="width: 25px; filter: invert(100%); display:block; margin: 0 auto;"
        />
      </template>
      <template #footer>
        <small>Horario de atención Lunes a viernes: 8am to 6pm</small>
      </template>
    </SocialChat>
  </div>
</template>

<script>
import { SocialChat } from "vue-social-chat";
export default {
  components: {
    SocialChat,
  },
  // name: 'MyNameComponent',

  setup() {
    const attendants = [
      {
        app: "whatsapp",
        label: "Jockey Club Chiclayo",
        name: "Atención al cliente",
        number: "51944978150",
        avatar: {
          src: require("@/assets/logos/logogrande.png"),
          alt: "JockeyClubChiclayo",
        },
      },
      // ...
    ];

    return { attendants };
  },
};
</script>


