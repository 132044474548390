<template lang="">
<Bannercomponent :tituloBannerSection="TituloBanner" />

<div class="content-section">
    <div class="container-xl d-flex flex-column justify-content-center">
        <div class="title-custom bg-transform">
            <h1 class="h1">publicaciones de facebook</h1>
            <p class="lead2"></p>
        </div>
        <div class="row row-deck row-cards py-4">
            <div class="col-sm-6 col-lg-4" v-for="post in posts" :key="post.id">
                <div class="card bg-muted-lt">
                    <div class="card-status-top bg-facebook"></div>
                    <div class="ribbon ribbon-top bg-facebook">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-facebook" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                        </svg>
                    </div>
                    <div class="card-header" style="background-color: #484848; color: #fff;">
                        <div class="row">
                            <div class="col-auto">
                                <img src="../assets/logos/883818710_n.jpg" alt="imgpagefb" class="avatar rounded img-fluid" style="object-fit: cover;" />
                            </div>
                            <div class="col">
                                <h3 class="card-title">JockeyClub Chiclayo</h3>
                                <div class="text-muted"><span class="badge bg-blue-lt">
                                        {{ formatDate(post.created_time) }} - {{ getTimeElapsed(post.created_time) }}
                                    </span></div>
                            </div>
                        </div>
                    </div>
                    <img :src="post.full_picture" alt="imgpostfb" class="d-block w-100 img-fluid" style="object-fit: cover; height: 400px; " />
                    <div class="card-body">
                        <br>
                        <div v-if="post.message">
                            <div v-html="truncateText(post.message, 100)"></div>
                        </div>
                        <br>
                        <div class="text-center align-items-center">
                            <a :href="post.permalink_url" class="btn btn-facebook" target="blank">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-facebook" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                                </svg>
                                Leer Publicacion
                            </a>
                        </div>
                    </div>

                </div>
            </div>
            <br>
        </div>
        
    </div>
</div>
</template>

<script>
import axios from 'axios';
import HttpService from "../Services/HttpService";
import Bannercomponent from '@/components/partials/Bannercomponent.vue';
export default {
    components: {
        Bannercomponent
    },
    data() {
        return {
            TituloBanner: "Nuestras últimas publicaciones",
            pageName: '',
            posts: [],
            videos: [],
            currentPage: 1,
            blogsPerPage: 6,
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.blogs.length / this.blogsPerPage);
        },

    },
    mounted() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        
        this.getFacebookPosts();

    },
    methods: {
        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            }
            return text;
        },
        formatDate(value) {
            const date = new Date(value);
            const day = date.getDate().toString().padStart(2, "0");
            const monthIndex = date.getMonth();
            const monthNames = [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre",
            ];
            const month = monthNames[monthIndex];
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        },
        getTimeElapsed(value) {
            const now = new Date();
            const createdAt = new Date(value);
            const diffInMilliseconds = now - createdAt;

            const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
            const diffInMinutes = Math.floor(diffInSeconds / 60);
            const diffInHours = Math.floor(diffInMinutes / 60);
            const diffInDays = Math.floor(diffInHours / 24);

            if (diffInDays > 0) {
                return `hace ${diffInDays} día(s)`;
            } else if (diffInHours > 0) {
                return `hace ${diffInHours} hora(s)`;
            } else if (diffInMinutes > 0) {
                return `hace ${diffInMinutes} minuto(s)`;
            } else {
                return `hace unos segundos`;
            }
        },
       
        getFullImagePathBlog(imagePath) {
            return `http://jockeyclubpanel.ml/public/images/blog/${imagePath}`; // Reemplaza "/static" con la ruta configurada en tu servidor de archivos estáticos
        },
        paginateBlogs() {
            const startIndex = (this.currentPage - 1) * this.blogsPerPage;
            const endIndex = startIndex + this.blogsPerPage;
            return this.blogs.slice(startIndex, endIndex);
        },
        // badge si en caso el blog sea nuevo
        isNewBlog(createdAt) {
            const now = new Date();
            const createdDate = new Date(createdAt);
            const diffInMilliseconds = now - createdDate;
            const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

            return diffInDays < 4;
        },
        getFacebookPosts() {
            // const accessToken = 'EAAQQ00hHU2cBAJvvBglAIb63zn2NVxtZBjl7NeUsnzCok4PQnQs5iNrGgVGrP8hC2W5OSGkr3RxyKg8WS9WiiUKv6y8gvaE0PUacp5SZCRAgZCfOvfMZBj5WXI8QOzp706WZCRNXgzQJ1EjZBDCuNUYSJqdGqGxDdxgdqyRgHzbrxZCPDFHCnMxiHBaDTShRi7imz3CMLQ2ck1jF0Htt4duFZAa19ROcZAaQZD';
            const accessToken = 'EAA2c50gI3OoBALGt8B2ANZAho0sZBZB25Xp9eQmu8jQsZCWRwSLZA81rhcbKg5CqAf303V9ZAY2t0BTXEm26UPZBj1d0mZAZArKIWHdxBaToGLOdltCOZCySaWpZBoYw2fb27TI1nqQFuBllwXmZCeZCujfewNZCMYrq5AEDZCsDUbh7MN5ZBDoszbHOnQdD';
            const pageId = '155603631139912';
            // const pageId = '217706428423429';

            axios.get(`https://graph.facebook.com/v17.0/${pageId}/posts`, {
                    params: {
                        access_token: accessToken,
                        fields: 'message,created_time,full_picture,permalink_url',
                    }
                })
                .then(response => {
                    this.pageName = response.data.name;
                    this.posts = response.data.data;
                })
                .catch(error => {
                    console.error(error);
                });
        }
    },

};
</script>
