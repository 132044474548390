<template lang="">
<Bannercomponent :tituloBannerSection="TituloBanner" />
<div class="content-section" style="padding: 5rem 0.5em;">
    <div class="container-xl d-flex flex-column justify-content-center">
        <div class="title-custom bg-transform">
            <h1 class="h1">contáctos</h1>
            <p class="lead2"></p>
        </div>
        <div class="row row-deck row-cards">
            <div class="col-md-6">
                <div class="row row-cards">
                    <div class="col-sm-12 col-lg-12" v-for="contacto in contactos" :key="contacto.ps_id">
                        <div class="card card-sm">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                            <img src="../assets/logos/883818710_n.jpg" alt="" class="avatar rounded-circle" style="width: 50px; height: 50px;">
                                    </div>
                                    <div class="col">
                                        <div class="font-weight-medium">
                                            <!-- {{ contacto.ps_persona }} -->
                                        </div>
                                        <div class="text-muted" style="text-transform:uppercase;">
                                            <h3>{{ contacto.ps_area }}</h3>
                                            {{ contacto.ps_descripcion }}
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <a :href="getWhatsAppLink(contacto.ps_telefono)" class="btn btn-primary w-100 btn-icon" target="_blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-message-2-check" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentcolor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M8 9h8" />
                                                <path d="M8 13h6" />
                                                <path d="M12 21l-1 -1l-2 -2h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v6" />
                                                <path d="M15 19l2 2l4 -4" />
                                            </svg>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-6 col-xl-6">
                <div class="card">
                    <div class="card-body">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3331.489963202854!2d-79.84067104065763!3d-6.790339929499463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x904cef3789f7a6df%3A0xa05e4c18de5f1c98!2sAv.%20los%20Incas%20379%2C%20Chiclayo%2014007!5e0!3m2!1ses-419!2spe!4v1687909858136!5m2!1ses-419!2spe" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import HttpService from "../Services/HttpService";
import Bannercomponent from '@/components/partials/Bannercomponent.vue';
export default {
    components: {
        Bannercomponent
    },
    mounted() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    },
    data() {
        return {
            TituloBanner: 'Contáctos',
            contactos: [],
        }

    },
    mounted() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        this.obtenerContactos();
    },

    methods: {
        getWhatsAppLink(phoneNumber) {
            const baseUrl = 'https://api.whatsapp.com/send';
            const encodedPhoneNumber = encodeURIComponent(phoneNumber);
            const link = `${baseUrl}?phone=${encodedPhoneNumber}&text=`;
            return link;
        },
        obtenerContactos() {
            HttpService.obtener("obtener_contactos.php").then((resultado) => {
                this.contactos = resultado;
            });
        }
    },
}
</script>

<style lang="">

    </style>
