<template lang="">
<Bannercomponent :tituloBannerSection="TituloBanner" />

</template>

<script>
import Bannercomponent from '@/components/partials/Bannercomponent.vue';
export default {
    components: {
        Bannercomponent
    },
    data() {
        return {
            TituloBanner: 'Nuestra Galería'
        }
    }
}
</script>

<style lang="">

</style>
