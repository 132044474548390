<template lang="">
    <div class="banner">
    <div class="image-container">
        <img src="../../assets/img/jockey.623a76a3.jpg" class="img-fluid" alt="Imagen">
        <div class="container-xl d-flex flex-column justify-content-center">
            <div class="title-overlay rounded">
                <div class="section-title">
                    <h1 style="color: #fff;font-size: 26px;">JockeyClub de Chiclayo</h1>
                    <!-- <p style="font-size: 18px;">Nuestros videos </p> -->
                    <p style="font-size: 18px;">{{ tituloBannerSection }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
  props: {
    tituloBannerSection: {
      type: String,
      default: "Nuestros videos"
    }
  }
};
</script>