import { createRouter, createWebHashHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import MultimediaView from '../views/MultimediaView.vue'
import BlogView from '../views/BlogView.vue'
// import ContactoView from '../views/ContactoView.vue'
import ContactoView from '../views/ContactoView.vue'
import StaffView from '../views/StaffView.vue'
// import BlogPostView from '../views/BlogPostView.vue'
import GaleriaView from '../views/GaleriaView.vue'
import ReclamosView from '../views/ReclamosView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/multimedia',
    name: 'MultimediaView',
    component: MultimediaView
  },
  {
    path: '/galeria',
    name: 'GaleriaView',
    component: GaleriaView
  },
  {
    path: '/publicaciones',
    name: 'publicaciones',
    component: BlogView
  },
  // {
  //   path: '/post',
  //   name: 'post',
  //   component: BlogPostView
  // },
  {
    path: '/staff',
    name: 'staff',
    component: StaffView
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: ContactoView
  },
  {
    path: '/reclamos',
    name: 'reclamos',
    component: ReclamosView
  },
  // {
  //   path: '/nosotros',
  //   name: 'nosotros',
  //   component: NosotrosView
  // },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
