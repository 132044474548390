<template lang="">
<header class="navbar  navbar-expand-md d-print-none">
    <div class="container-xl">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu" aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
            <router-link to="/" class="navbar-brand-image" href="#">
                <img src="../../assets/jockey-club-chiclayo.png" width="110" height="50" alt="Jockey Club de Chiclayo" class="navbar-brand-image">
            </router-link>
        </div>
        <div class="navbar-nav navbar-links flex-row order-md-last">
            <div class="d-none d-md-flex">
                <div class="nav-link px-0">
                    <phone-icon />&nbsp; (074) 232451 &nbsp;
                </div>
                <div class="nav-link px-0">
                    <mail-icon />&nbsp; informes@jockeyclubchiclayo.pe &nbsp;
                </div>
            </div>
            <div class="nav-item d-none d-md-flex me-3">
                <div class="btn-list">
                    <router-link to="/" class="btn btn-lime btn-icon" href="#">
                        &nbsp;<calendar-icon />
                        Reservaciones&nbsp;
                    </router-link>
                    <a class="btn btn-icon bg-muted text-muted-fg" href="https://jockeyclubchiclayo.pe/webmail/log-in" target="_blank">
                        &nbsp;<send-icon />
                        Webmail &nbsp;
                    </a>

                    <a class="btn btn-icon bg-facebook text-muted-fg" href="https://www.facebook.com/jockeyclubchiclayo" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-facebook" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                        </svg>
                    </a>
                    <a class="btn bg-pink btn-icon text-muted-fg" href="https://www.instagram.com/jockeyclubchiclayo/" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-instagram" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
                            <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                            <path d="M16.5 7.5l0 .01" />
                        </svg>
                    </a>
                </div>
            </div>

        </div>
    </div>
</header>
<header class="navbar-expand-md  d-print-none" data-bs-theme="dark">
    <div class="collapse navbar-collapse" id="navbar-menu">
        <div class="navbar ">
            <div class="container-xl">
                <ul class="navbar-nav navbar-custom" style="text-transform: uppercase">
                    <li :class="{ 'nav-item': true,  active: isActive('/') }">

                        <router-link to="/" class="nav-link" aria-current="page" href="#">
                            <!-- <span class="nav-link-icon d-md-none d-lg-inline-block">
                                <home-icon /></span> -->
                            <span class="nav-link-title">INICIO</span>
                        </router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            NOSOTROS
                        </a>
                        <div class="dropdown-menu">
                    <li class="nav-item">
                        <router-link to="/about" class="dropdown-item" href="#">
                            <span class="nav-link-title">Insititución</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/staff" class="dropdown-item" href="#">
                            <span class="nav-link-title">Junta Directiva</span>
                        </router-link>
                    </li>
            </div>
            </li>
            <li :class="{ 'nav-item': true, active: isActive('/publicaciones') }">
                <router-link to="/publicaciones" class="nav-link" aria-current="page" href="#">publicaciones</router-link>
            </li>
            <li :class="{ 'nav-item': true, active: isActive('/multimedia') }">
                <router-link to="/multimedia" class="nav-link" aria-current="page" href="#">Multimedia</router-link>
            </li>
            <li :class="{ 'nav-item': true, active: isActive('/galeria') }">
                <router-link to="/galeria" class="nav-link" aria-current="page" href="#">Galería</router-link>
            </li>
            <li :class="{ 'nav-item': true, active: isActive('/reclamos') }">
                <router-link to="/reclamos" class="nav-link" aria-current="page" href="#">Libro de reclamaciones</router-link>
            </li>
            <li :class="{ 'nav-item': true, active: isActive('/contacto') }">
                <router-link to="/contacto" class="nav-link" aria-current="page" href="#">contacto</router-link>
            </li>
            </ul>
        </div>
    </div>
    </div>
</header>
</template>

<script>
export default {
    methods: {
        isActive(route) {
            return this.$route.path === route;
        },
    },
}
</script>

<style lang="">

</style>
