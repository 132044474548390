<template>
  <div>
    <div id="fb-root"></div>
    <div id="fb-customer-chat" class="fb-customerchat"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.loadFacebookSDK().then(() => {
      this.initializeMessengerChat();
    });
  },
  methods: {
    loadFacebookSDK() {
      return new Promise((resolve) => {
        window.fbAsyncInit = function() {
          FB.init({
            xfbml: true,
            version: 'v17.0'
          });
          resolve();
        };

        (function(d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src = 'https://connect.facebook.net/es_LA/sdk/xfbml.customerchat.js';
          fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
      });
    },
    initializeMessengerChat() {
      var chatbox = document.getElementById('fb-customer-chat');
      chatbox.setAttribute('page_id', '155603631139912');
      chatbox.setAttribute('attribution', 'biz_inbox');

      // Renderizar el plugin de chat en el DOM
      window.FB.XFBML.parse();
    }
  }
};
</script>

<style>
/* Agrega estilos personalizados aquí si es necesario */
</style>
